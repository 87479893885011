<template>
    <v-container fluid>
        <v-row row wrap>
            
            <v-col cols="12" pl-3>
                <v-btn
                    v-model="enable"
                    block outlined elevation="2"
                    @click="enable=!enable"
                    > Gift membership </v-btn>
            </v-col>

            <template v-if="enable">
                <v-col cols="12" class="pl-4 pr-4 pt-2 mb-0 pb-0">
                    <div class="font-weight-thin">Use this form to permanently gift a membership. After gifting membership, make sure to "Create a New Square Profile" for new member.</div>
                </v-col>
                <v-col cols="12" class="pa-4">
                    <v-text-field v-model="giftee.firstName" label="First Name (Giftee)"></v-text-field>
                    <v-text-field v-model="giftee.lastName" label="Last Name (Giftee)"></v-text-field>
                    <v-text-field v-model="giftee.email" label="Email (Giftee)"></v-text-field>
                    <v-btn color="blue darken-1" outlined :loading ="giftLoading" @click="confirmGift=true">Gift Membership</v-btn>
                </v-col>
            </template>

            <v-dialog v-model="confirmGift" persistent max-width="380">
                <v-card>
                  <v-card-title primary-title>
                      Are you sure?
                  </v-card-title>
                  <v-card-text>
                      This will transfer the ownership of this account from the current member to the giftee. 
                          The current member's access to this account will be removed, a new login will be created for the giftee, 
                          and an email will be sent to both informing them of the gift.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" outlined @click="giftMembership()" :loading="giftLoading">Yes, Gift It Now</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="confirmGift = false" :loading="giftLoading">No, Cancel</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
        
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import vuex from 'vuex'
export default {
    data () {
        return {
            showGiftPane: false,
            enable: false,
            giftee: {},
            confirmGift: false,
            giftLoading: false,
        }
    },
    props: ['member'],
    methods: {
        async giftMembership () { //this function is only accessible after sending to admin, enforced by dialog popup sequence
            try{
                let newUser = this.giftee
                newUser.firstName = newUser.firstName.trim()
                newUser.lastName = newUser.lastName.trim()
                newUser.email = newUser.email.trim()
                newUser.fullName = newUser.firstName + " " + newUser.lastName
                this.giftLoading = true

                var functionsCall = global.FIREBASE.functions.httpsCallable('giftmembership')
                let response = await functionsCall(
                    {
                        uid: this.$store.state.user.uid, 
                        adminName: this.$store.state.userInfo.fullName, 
                        userInfo: this.member,
                        newUserInfo: newUser,
                    }
                )
                console.log(response)
                this.giftLoading = false
                this.confirmGift = false
                this.refresh()
            } catch (error) {
                this.giftLoading = false
                this.confirmGift = false
                console.log(error)
            } 
            
        },
        async giftFreeMembership(){
            this.giftFirst = this.giftFirst.trim()
            this.giftLast = this.giftLast.trim()
            this.giftEmail = this.giftEmail.trim()

            this.setLoading(true)
            this.sending = true
            let uid = this.$store.state.user.uid
            this.showGift = false

            var functionsCall = global.FIREBASE.functions.httpsCallable('giftfreemembership')
            let response = await functionsCall(
                {
                    uid: uid, 
                    user: {first: this.giftFirst, last: this.giftLast, email: this.giftEmail}, 
                    type: "saguaro", 
                    year: this.giftYear,
                    reason: this.giftReason
                }
            )
            console.log(response)
            this.clearGift()
            this.dialogTitle = response.data.status.toUpperCase()
            this.dialogMessage = response.data.message
            this.showDialog = true
            return
        },
    }
}
</script>