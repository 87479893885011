<template>              
        <v-card flat class="ma-5 pa-5" max-width="500" outlined>
        <v-row row wrap>
            
            <v-col cols="12" pt-3 ma-3>
                <div class="headline">{{ member.fullName }} <br></div> 
                <div class="font-weight-thin">Email: {{ member.email }}</div>
                <div class="font-weight-thin">Member #{{ member.memberId }}</div>
                <div class="font-weight-thin">Tier: {{ member.userType }}</div>
                <div class="font-weight-thin">Proxy: {{ member.proxy }}</div>
                <div class="font-weight-thin">Joined: {{ member.joinDate }}</div>
                <div class="font-weight-thin">Expire: {{ member.expireDate }}</div>
                <div class="font-weight-thin">Last Renewed: {{ member.expireDate }}</div>
                
                
                
            </v-col>

            <member-order-extension
                :member="member"
                item-key="uid"
            />

            <record-order 
                :member="member" 
                item-key="uid"
            />    

            <gift-membership 
                :member="member"
                item-key="uid"
            />

            <create-new-square-profile 
                :member="member"
                item-key="uid"
            />

        </v-row>
    </v-card>
</template>

<script>
import GiftMembership from './GiftMembership.vue'
import MemberOrderExtension from './MemberOrderExtension.vue'
import RecordOrder from './RecordOrder.vue'
import CreateNewSquareProfile from './CreateNewSquareProfile.vue'
export default {
    name: 'MembershipActions',
    data () {
        return {

        }
    },
    components: {
        GiftMembership,
        MemberOrderExtension,
        RecordOrder,
        CreateNewSquareProfile,
    },
    props: ['member'],
    methods: {

    }
}
</script>