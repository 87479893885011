<template>
    <v-container fluid>
        <v-row row wrap>
        
            <v-col cols="12" class="pl-3">
                <v-btn
                    v-model="enable"
                    block outlined elevation="2"
                    @click="enable=!enable"
                    > Create New Square Profile </v-btn>
            </v-col>

            <template v-if="enable">
                <v-col cols="12" class="pl-4 pr-4 pt-2 mb-0 pb-0">
                    <div class="font-weight-thin">Botls needs to access Square customer IDs to process payments. 
                        The link between botls and Square can be lost, for instance, (1) the membership has been 
                        gifted or (2) the member's previous profile has been edited within the square dashboard.</div>
                </v-col>
                <v-col cols="12" class="pl-4 pr-4 pt-2 mb-0 pb-0">
                    <div class="font-weight-thin">Click the button below to create a new profile for this member to be linked
                        with botls. This will not delete or merge old profiles in Square.</div>
                </v-col>
                <v-col cols="12" class="pa-4 ma-2">
                    <template v-if="loading">
                        <v-progress-circular
                            :width="3"
                            color="blue"
                            indeterminate
                        ></v-progress-circular>
                    </template>
                    <template v-else>
                        <v-btn outlined color="primary" @click="createSquareCustomerProfile" :loading="loading" :disabled="loading">
                            Create New Square Profile
                        </v-btn>
                    </template>
                </v-col>
            </template>

        </v-row>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            enable: false,
            extendSwitch: false,
            loading: false,
        }
    },
    props: ['member'],
    methods: {
        async createSquareCustomerProfile () {
            this.loading = true
            let uid = this.$store.state.user.uid
            var functionsCall = global.FIREBASE.functions.httpsCallable('createsquarecustomers')
            let response = await functionsCall(
                {
                    uid: uid, 
                    users: [this.member]
                }
            )
            this.loading = false
            return response
        },
    }
}
</script>