<template>
    <v-container fluid>
        <v-row row wrap>
        
            <v-col cols="12" class="pl-3">
                <v-btn
                    v-model="enable"
                    block outlined elevation="2"
                    @click="enable=!enable"
                    > Extend Order Deadline </v-btn>
            </v-col>

            <template v-if="enable">
                <v-col cols="12" class="pl-4 pr-4 pt-2 mb-0 pb-0">
                    <div class="font-weight-thin">Members and products are extended independently. The product extension determines the date and time of the extended deadline. 
                        The member extension (the switch below) activates all current product extensions for this particular member.</div>
                </v-col>
                <v-col cols="12" class="pl-4 mt-0 pt-0">
                    <template v-if="loading">
                        <v-progress-circular
                            :width="3"
                            color="blue"
                            indeterminate
                        ></v-progress-circular>
                    </template>
                    <template v-else>
                        <v-switch v-model="extendSwitch" @change="updateMemberExtension()" >
                            <template v-slot:label>
                                Member extension {{extendSwitch? "ON": "OFF"}} 
                            </template>
                        </v-switch>
                    </template>
                </v-col>
            </template>

        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    data () {
        return {
            enable: false,
            extendSwitch: false,
        //    loading: false,
        }
    },
    mounted () {
        //this.getMemberExtension()
        this.extendSwitch = this.member.extended
    },
    props: ['member'],
    computed: {
        ...mapGetters([
            'loading'
        ]),
    },
    methods: {
        ...mapMutations([
            'setLoading'
        ]),
        updateMemberExtension() {
            let user = this.member
            let self = this
            this.setLoading(true)
            let update = {}
            update.extended = this.extendSwitch
            FIREBASE.db.collection("users").doc(user.uid).update(update)
            .then(function() {
                self.setLoading(false)
                console.log("updated profile")
            })
            .catch(function(error) {
                self.setLoading(false)
                console.error("Error updating profile: ", error)
            })
        },
        // getMemberExtension() {
        //     let user = this.member
        //     let self = this
        //     this.setLoading(true)
        //     FIREBASE.db.collection("users").doc(user.uid).get()
        //     .then(function(doc) {
        //         self.extendSwitch = false
        //         if(doc.data().extended != undefined){
        //             self.extendSwitch = doc.data().extended
        //         }
        //         self.setLoading(false)
        //         console.log("retreived value = " + self.extendSwitch)
        //     })
        //     .catch(function(error) {
        //         self.setLoading(false)
        //         console.error("Error updating profile: ", error)
        //     })
        // }
    }
}
</script>